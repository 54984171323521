// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../styleguide/components/Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Api from "../../api/Api.res.js";
import * as Url from "../../utils/Url.res.js";
import * as Form from "../../styleguide/forms/Form/Form.res.js";
import * as Hooks from "../../libs/Hooks.res.js";
import * as Label from "../../styleguide/forms/Label/Label.res.js";
import * as Button from "../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../bindings/Promise.res.js";
import * as IconChat from "../../styleguide/icons/IconChat.res.js";
import * as Textarea from "../../styleguide/forms/Textarea/Textarea.res.js";
import * as IconPhone from "../../styleguide/icons/IconPhone.res.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as TextField from "../../styleguide/forms/TextField/TextField.res.js";
import * as Cloudinary from "../../libs/Cloudinary.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Routes_Page from "../../routes/common/Routes_Page.res.js";
import * as SentryLogger from "../../loggers/SentryLogger.res.js";
import * as ContactUsData from "./ContactUsData.res.js";
import * as ReactTelInput from "../../bindings/ReactTelInput.res.js";
import * as ContactScss from "./Contact.scss";
import * as TelephoneField from "../../styleguide/forms/TelephoneField/TelephoneField.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Global from "Client/common/global";

var css = ContactScss;

var initialState_input = ContactUsData.Input.empty();

var initialState = {
  isSubmitted: false,
  input: initialState_input,
  results: undefined,
  serverError: undefined
};

function Contact$default(props) {
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action === "object") {
            if (action.TAG === "ServerError") {
              return {
                      TAG: "UpdateWithSideEffects",
                      _0: {
                        isSubmitted: state.isSubmitted,
                        input: state.input,
                        results: state.results,
                        serverError: action._0
                      },
                      _1: (function (param) {
                          param.dispatch("AfterSubmit");
                        })
                    };
            } else {
              return {
                      TAG: "Update",
                      _0: {
                        isSubmitted: state.isSubmitted,
                        input: action._0,
                        results: state.results,
                        serverError: state.serverError
                      }
                    };
            }
          }
          switch (action) {
            case "Validate" :
                return {
                        TAG: "Update",
                        _0: {
                          isSubmitted: state.isSubmitted,
                          input: state.input,
                          results: ContactUsData.Validate.all(state.input),
                          serverError: state.serverError
                        }
                      };
            case "Submit" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          isSubmitted: state.isSubmitted,
                          input: state.input,
                          results: ContactUsData.Validate.all(state.input),
                          serverError: state.serverError
                        },
                        _1: (function (param) {
                            var dispatch = param.dispatch;
                            var state = param.state;
                            if (ContactUsData.Validate.valid(Belt_Option.getExn(state.results))) {
                              return $$Promise.wait(Api.sendContactMessage(state.input), (function (x) {
                                            if (x.TAG === "Ok") {
                                              return Url.visit(Routes_Page.contactThankYou);
                                            }
                                            SentryLogger.error1({
                                                  rootModule: "Contact",
                                                  subModulePath: /* [] */0,
                                                  value: "default",
                                                  fullPath: "Contact.default"
                                                }, "Contact::Error", [
                                                  "Error",
                                                  x._0
                                                ]);
                                            dispatch({
                                                  TAG: "ServerError",
                                                  _0: "Something went wrong"
                                                });
                                          }));
                            } else {
                              return dispatch("AfterSubmit");
                            }
                          })
                      };
            case "AfterSubmit" :
                return {
                        TAG: "Update",
                        _0: {
                          isSubmitted: false,
                          input: state.input,
                          results: state.results,
                          serverError: state.serverError
                        }
                      };
            case "BeforeSubmit" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          isSubmitted: true,
                          input: state.input,
                          results: state.results,
                          serverError: state.serverError
                        },
                        _1: (function (param) {
                            param.dispatch("Submit");
                          })
                      };
            
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("h1", {
                                    children: "Get Expert Advice",
                                    className: css.h1
                                  }),
                              JsxRuntime.jsx("h2", {
                                    children: "Do you need a consultation? Contact us or submit the form above and we'll do our best to help you.",
                                    className: css.h2
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx("img", {
                                                    className: css.imgPerson,
                                                    height: "100",
                                                    src: Cloudinary.imageUrl("f_auto/v1615844503/static/joel-st.germain.png"),
                                                    width: "100"
                                                  }),
                                              JsxRuntime.jsxs("div", {
                                                    children: [
                                                      JsxRuntime.jsx("p", {
                                                            children: "Joel St. Germain",
                                                            className: css.contactPersonName
                                                          }),
                                                      JsxRuntime.jsx("p", {
                                                            children: "We're here to help",
                                                            className: css.contactPersonDesc
                                                          })
                                                    ],
                                                    className: css.contactPersonDescContainer
                                                  })
                                            ],
                                            className: css.contactPersonContainer
                                          }),
                                      JsxRuntime.jsx("div", {
                                            className: css.divider
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(IconPhone.make, {
                                                  size: "SM",
                                                  color: "Gray",
                                                  className: css.iconMargin
                                                }),
                                            className: css.contactPersonDescData
                                          }),
                                      JsxRuntime.jsx(A.make, {
                                            href: Global.DCContactNumberHRef,
                                            children: Global.DCContactNumber
                                          }),
                                      JsxRuntime.jsx("div", {
                                            className: css.divider
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(IconChat.make, {
                                                    size: "SM",
                                                    color: "Gray",
                                                    className: css.iconMargin
                                                  }),
                                              JsxRuntime.jsx(A.make, {
                                                    href: Routes_Page.contact,
                                                    children: " Contact us"
                                                  })
                                            ],
                                            className: css.contactPersonDescData
                                          }),
                                      JsxRuntime.jsx("div", {
                                            className: css.divider
                                          })
                                    ],
                                    className: css.personContainerMobile
                                  }),
                              JsxRuntime.jsxs(Form.make, {
                                    onSubmit: (function (param) {
                                        dispatch("BeforeSubmit");
                                      }),
                                    children: [
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsxs("div", {
                                                    children: [
                                                      JsxRuntime.jsx(Label.make, {
                                                            forId: "contacUs-form--first-name",
                                                            children: "First name"
                                                          }),
                                                      JsxRuntime.jsx(TextField.make, {
                                                            id: "contacUs-form--first-name",
                                                            value: state.input.firstName,
                                                            status: Belt_Option.map(state.results, (function (x) {
                                                                    if (x.firstName.TAG === "Ok") {
                                                                      return "Valid";
                                                                    } else {
                                                                      return "Error";
                                                                    }
                                                                  })),
                                                            onChange: (function ($$event) {
                                                                var init = state.input;
                                                                dispatch({
                                                                      TAG: "Update",
                                                                      _0: {
                                                                        firstName: $$event.target.value,
                                                                        lastName: init.lastName,
                                                                        email: init.email,
                                                                        companyName: init.companyName,
                                                                        phoneNumber: init.phoneNumber,
                                                                        dialCountryCode: init.dialCountryCode,
                                                                        country: init.country,
                                                                        message: init.message
                                                                      }
                                                                    });
                                                              })
                                                          })
                                                    ],
                                                    className: css.firstNameContainer
                                                  }),
                                              JsxRuntime.jsxs("div", {
                                                    children: [
                                                      JsxRuntime.jsx(Label.make, {
                                                            forId: "contacUs-form--last-name",
                                                            children: "Last name"
                                                          }),
                                                      JsxRuntime.jsx(TextField.make, {
                                                            id: "contacUs-form--last-name",
                                                            value: state.input.lastName,
                                                            status: Belt_Option.map(state.results, (function (x) {
                                                                    if (x.lastName.TAG === "Ok") {
                                                                      return "Valid";
                                                                    } else {
                                                                      return "Error";
                                                                    }
                                                                  })),
                                                            onChange: (function ($$event) {
                                                                var init = state.input;
                                                                dispatch({
                                                                      TAG: "Update",
                                                                      _0: {
                                                                        firstName: init.firstName,
                                                                        lastName: $$event.target.value,
                                                                        email: init.email,
                                                                        companyName: init.companyName,
                                                                        phoneNumber: init.phoneNumber,
                                                                        dialCountryCode: init.dialCountryCode,
                                                                        country: init.country,
                                                                        message: init.message
                                                                      }
                                                                    });
                                                              })
                                                          })
                                                    ],
                                                    className: css.lastNameContainer
                                                  })
                                            ],
                                            className: Cx.cx([
                                                  css.doubleColumnContainer,
                                                  css.formRow
                                                ])
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "contacUs-form--company-name",
                                                    children: "Company name"
                                                  }),
                                              JsxRuntime.jsx(TextField.make, {
                                                    id: "contacUs-form--company-name",
                                                    value: state.input.companyName,
                                                    status: Belt_Option.map(state.results, (function (x) {
                                                            if (x.companyName.TAG === "Ok") {
                                                              return "Valid";
                                                            } else {
                                                              return "Error";
                                                            }
                                                          })),
                                                    onChange: (function ($$event) {
                                                        var init = state.input;
                                                        dispatch({
                                                              TAG: "Update",
                                                              _0: {
                                                                firstName: init.firstName,
                                                                lastName: init.lastName,
                                                                email: init.email,
                                                                companyName: $$event.target.value,
                                                                phoneNumber: init.phoneNumber,
                                                                dialCountryCode: init.dialCountryCode,
                                                                country: init.country,
                                                                message: init.message
                                                              }
                                                            });
                                                      })
                                                  })
                                            ],
                                            className: css.formRow
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "contacUs-form--email",
                                                    children: "Email"
                                                  }),
                                              JsxRuntime.jsx(TextField.make, {
                                                    id: "contacUs-form--email",
                                                    value: state.input.email,
                                                    status: Belt_Option.map(state.results, (function (x) {
                                                            if (x.email.TAG === "Ok") {
                                                              return "Valid";
                                                            } else {
                                                              return "Error";
                                                            }
                                                          })),
                                                    onChange: (function ($$event) {
                                                        var init = state.input;
                                                        dispatch({
                                                              TAG: "Update",
                                                              _0: {
                                                                firstName: init.firstName,
                                                                lastName: init.lastName,
                                                                email: $$event.target.value,
                                                                companyName: init.companyName,
                                                                phoneNumber: init.phoneNumber,
                                                                dialCountryCode: init.dialCountryCode,
                                                                country: init.country,
                                                                message: init.message
                                                              }
                                                            });
                                                      })
                                                  })
                                            ],
                                            className: css.formRow
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsxs("div", {
                                                    children: [
                                                      JsxRuntime.jsx(Label.make, {
                                                            forId: "contacUs-form--telephone",
                                                            children: "Phone"
                                                          }),
                                                      JsxRuntime.jsx(TelephoneField.make, {
                                                            id: "contacUs-form--telephone",
                                                            value: state.input.phoneNumber,
                                                            defaultCountry: "us",
                                                            status: Belt_Option.map(state.results, (function (x) {
                                                                    if (x.phoneNumber.TAG === "Ok") {
                                                                      return "Valid";
                                                                    } else {
                                                                      return "Error";
                                                                    }
                                                                  })),
                                                            onChange: (function (value, selectedCountry) {
                                                                var country = ReactTelInput.ReactTelInputData.countryFromJs(selectedCountry);
                                                                var init = state.input;
                                                                dispatch({
                                                                      TAG: "Update",
                                                                      _0: {
                                                                        firstName: init.firstName,
                                                                        lastName: init.lastName,
                                                                        email: init.email,
                                                                        companyName: init.companyName,
                                                                        phoneNumber: "+" + (country.dialCode + Js_string.replace("+" + country.dialCode, "", value).trim()),
                                                                        dialCountryCode: "+" + country.dialCode,
                                                                        country: country.iso2.toUpperCase(),
                                                                        message: init.message
                                                                      }
                                                                    });
                                                              }),
                                                            preferredCountries: ["us"]
                                                          })
                                                    ],
                                                    className: css.phoneContainer
                                                  }),
                                              JsxRuntime.jsx("div", {
                                                    className: css.phoneGhostContainer
                                                  })
                                            ],
                                            className: Cx.cx([
                                                  css.doubleColumnContainer,
                                                  css.formRow
                                                ])
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "contactUs-form--message",
                                                    children: "Message"
                                                  }),
                                              JsxRuntime.jsx(Textarea.make, {
                                                    id: "contactUs-form--message",
                                                    value: state.input.message,
                                                    placeholder: "Tell us about your company, your situation, and what we can best help you with",
                                                    status: Belt_Option.map(state.results, (function (x) {
                                                            if (x.message.TAG === "Ok") {
                                                              return "Valid";
                                                            } else {
                                                              return "Error";
                                                            }
                                                          })),
                                                    className: css.messageField,
                                                    onChange: (function ($$event) {
                                                        var init = state.input;
                                                        dispatch({
                                                              TAG: "Update",
                                                              _0: {
                                                                firstName: init.firstName,
                                                                lastName: init.lastName,
                                                                email: init.email,
                                                                companyName: init.companyName,
                                                                phoneNumber: init.phoneNumber,
                                                                dialCountryCode: init.dialCountryCode,
                                                                country: init.country,
                                                                message: $$event.target.value
                                                              }
                                                            });
                                                      })
                                                  })
                                            ],
                                            className: css.formRow
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Button.make, {
                                                    size: "MD",
                                                    color: "Primary",
                                                    busy: state.isSubmitted,
                                                    submit: true,
                                                    children: "Submit Form"
                                                  }),
                                              state.isSubmitted ? JsxRuntime.jsx("p", {
                                                      children: "Thanks! Your request has been submitted.",
                                                      className: css.submitMessage
                                                    }) : null
                                            ],
                                            className: css.submitRow
                                          })
                                    ]
                                  })
                            ],
                            className: css.leftContent
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx("img", {
                                                  className: css.imgPerson,
                                                  height: "100",
                                                  src: Cloudinary.imageUrl("f_auto/v1615844503/static/joel-st.germain.png"),
                                                  width: "100"
                                                }),
                                            JsxRuntime.jsxs("div", {
                                                  children: [
                                                    JsxRuntime.jsx("p", {
                                                          children: "Joel St. Germain",
                                                          className: css.contactPersonName
                                                        }),
                                                    JsxRuntime.jsx("p", {
                                                          children: "We're here to help",
                                                          className: css.contactPersonDesc
                                                        })
                                                  ],
                                                  className: css.contactPersonDescContainer
                                                })
                                          ],
                                          className: css.contactPersonContainer
                                        }),
                                    JsxRuntime.jsx("div", {
                                          className: css.divider
                                        }),
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(IconPhone.make, {
                                                  size: "SM",
                                                  color: "Gray",
                                                  className: css.iconMargin
                                                }),
                                            JsxRuntime.jsx(A.make, {
                                                  href: Global.DCContactNumberHRef,
                                                  children: Global.DCContactNumber
                                                })
                                          ],
                                          className: css.contactPersonDescData
                                        }),
                                    JsxRuntime.jsx("div", {
                                          className: css.divider
                                        }),
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(IconChat.make, {
                                                  size: "SM",
                                                  color: "Gray",
                                                  className: css.iconMargin
                                                }),
                                            JsxRuntime.jsx(A.make, {
                                                  href: Routes_Page.contact,
                                                  children: " Contact us"
                                                })
                                          ],
                                          className: css.contactPersonDescData
                                        }),
                                    JsxRuntime.jsx("div", {
                                          className: css.divider
                                        })
                                  ],
                                  className: css.personContainerDesktop
                                }),
                            className: css.rightContent
                          })
                    ],
                    className: css.pageContent
                  }),
              className: css.pageContainer
            });
}

var Input;

var Validate;

var ValidationResult;

var ReactTelInputData;

var $$default = Contact$default;

export {
  css ,
  Input ,
  Validate ,
  ValidationResult ,
  ReactTelInputData ,
  initialState ,
  $$default as default,
}
/* css Not a pure module */
