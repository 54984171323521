// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconChat(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Chat";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("path", {
                    d: "M8.33.6c3.046.014 6.87.3 6.87 5.6 0 3.685-1.849 4.946-4 5.377V14.2l-2.405-2.405c-.27.004-.536.005-.795.005-3.093 0-7.2-.107-7.2-5.6C.8.995 4.489.626 7.507.602L7.671.6h.658zm-.061 1.6H7.73c-4.058.03-5.331.742-5.331 4 0 3.329 1.33 4 5.6 4s5.6-.671 5.6-4c0-3.187-1.219-3.938-5.07-3.996L8.268 2.2zM5.6 5.4a.8.8 0 1 1 0 1.6.8.8 0 0 1 0-1.6zm2.4 0A.8.8 0 1 1 8 7a.8.8 0 0 1 0-1.6zm2.4 0a.8.8 0 1 1 0 1.6.8.8 0 0 1 0-1.6z",
                    fill: Icon.mapColor(props.color)
                  })
            });
}

var make = IconChat;

export {
  make ,
}
/* Icon Not a pure module */
